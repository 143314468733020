import { TerminalProvider } from './../../providers/terminal.provider';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(private terminalProvider: TerminalProvider) { }
  @Input() links = false;

  ngOnInit(): void {
  }

  execute(command): void {
    this.terminalProvider.execute(command);
  }

}
