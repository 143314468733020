import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  public team = [
    {
      name: 'Jens Eriksson',
      email: 'jens.eriksson@it-e.se',
      photo: 'jens.png'
    },
    {
      name: 'Jörgen Terner',
      email: 'jorgen.terner@it-e.se',
      photo: 'jorgen.png'
    },
    {
      name: 'Mats Egland',
      email: 'mats.egland@it-e.se',
      photo: 'mats.png'
    },
    {
      name: 'Mårten Holm',
      email: 'marten.holm@it-e.se',
      photo: 'avatar.png'
    },
    {
      name: 'Per Erskjäns',
      email: 'per.erskans@it-e.se',
      photo: 'avatar.png'
    },
    {
      name: 'Ulrik Arnström',
      email: 'ulrik.arnstrom@it-e.se',
      photo: 'avatar.png'
    },
    {
      name: 'Tomas Andersson',
      email: 'tomas.andersson@it-e.se',
      photo: 'avatar.png'
    },
    {
      name: 'Pontus Ellström',
      email: 'pontus.ellstrom@it-e.se',
      photo: 'avatar.png'
    },
    {
      name: 'Emil Palmqvist',
      email: 'emil.palmqvist@it-e.se',
      photo: 'avatar.png'
    }
  ];

  constructor() { 
    this.team.sort((a, b) => {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    });
  }

  ngOnInit(): void {
  }

}
