<div id="wrapper">
    <div id="toolbar">
        <div id="help">
            <img id="help-button" src="../../assets/help.png" (click)="toogleHelp()" />
            <div id="help-text" *ngIf="showHelp">
                COMMANDS
                <app-help [links]="true"></app-help>
            </div>
        </div>
    </div>
    <div id="window" #window>
        <img id="ascii-logo" src="../../assets/logo/ascii-logo.png" />
        <app-welcome-message></app-welcome-message>
        <ng-container #content>
            <ng-template #promptTemplate let-command="text">
                <span>{{ prompt + command }}</span><br>
            </ng-template>
        </ng-container>
        <span>{{ prompt + input }}</span><span id="cursor">|</span>
    </div>
    <img id="footer-logo" src="../../assets/logo/footer-logo.png" />
</div>


