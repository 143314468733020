<table>
    <tr>
        <td rowspan="3">
            <img src="../../../assets/jens.png" />
        </td>
        <td>Jens Eriksson</td>
    </tr>
    <tr>
        <td>jens.eriksson@it-e.se</td>
    </tr>
    <tr>
        <td>070-6883099</td>
    </tr>
    <tr><td>&nbsp;</td></tr>
    <tr>
        <td rowspan="5">
            <a href="https://maps.app.goo.gl/X2Ac2SfTLdd4Mh1M6" target="_blank"><img src="../../../assets/pin2.jpeg" /></a> 
        </td>
    </tr>
    <tr>
        <td>IT-Entreprenörerna Sverige AB</td>
    </tr>
    <tr>
        <td>Kyrkogatan 24</td>
    </tr>
    <tr>
        <td>852 32 Sundsvall</td>
    </tr>
    <tr>
        <td>Sweden</td>
    </tr>

</table>
