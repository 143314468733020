<table>
    <tr>
        <td *ngIf="links"><a href="#" (click)="execute('about')">about</a></td>
        <td *ngIf="!links">about</td>
        <td>Information about it-e</td>
    </tr>
    <tr>
        <td *ngIf="links"><a href="#" (click)="execute('contact')">contact</a></td>
        <td *ngIf="!links">contact</td>
        <td>Who to contact</td>
    </tr>
    <tr>
        <td *ngIf="links"><a href="#" (click)="execute('team')">team</a></td>
        <td *ngIf="!links">team</td>
        <td>The dream team</td>
    </tr>
    <tr>
        <td *ngIf="links"><a href="#" (click)="execute('motto')">motto</a></td>
        <td *ngIf="!links">motto</td>
        <td>How we think</td>
    </tr>
    <tr>
        <td *ngIf="links"><a href="#" (click)="execute('help')">help</a></td>
        <td *ngIf="!links">help</td>
        <td>Show help</td>
    </tr>
    <tr>
        <td *ngIf="links"><a href="#" (click)="execute('clear')">clear</a></td>
        <td *ngIf="!links">clear</td>
        <td>Clears terminal</td>
    </tr>
</table>