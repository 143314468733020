<div class="container">
    <div class="person" *ngFor="let person of team">
        <table>
            <tr>
                <td rowspan="3" class="col-1">
                    <img src="{{ '../../../assets/' + person.photo }}" />
                </td>
                <td>{{ person.name }}</td>
            </tr>
            <tr>
                <td class="col-2">{{ person.email }}</td>
            </tr>
        </table>
    </div>
</div>
