import { TeamComponent } from './../commands/team/team.component';
import { MottoComponent } from './../commands/motto/motto.component';
import { HelpComponent } from './../commands/help/help.component';
import { CommandNotFoundComponent } from './../commands/command-not-found/command-not-found.component';
import { ContactComponent } from './../commands/contact/contact.component';
import { AboutComponent } from './../commands/about/about.component';
import { TerminalProvider } from './../providers/terminal.provider';
import { Component, OnInit, HostListener, ViewChild, ElementRef,
          ViewContainerRef, ComponentFactoryResolver, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef }) private content: ViewContainerRef;
  @ViewChild('promptTemplate', { read: TemplateRef }) private promptTemplate: TemplateRef<any>;
  @ViewChild('window') private window: ElementRef;
  prompt = 'homepage@it-e ~$ ';
  input: string;
  showHelp = false;

  constructor(private terminalProvider: TerminalProvider,
              private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.terminalProvider.commandExecuted.subscribe(command => {
      this.processCommand(command);
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    });

    this.terminalProvider.userInput.subscribe(input => {
      this.input = input;
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    this.terminalProvider.onKeyPress(event);
  }

  scrollToBottom(): void {
    if (this.window) {
      this.window.nativeElement.scrollTop = this.window.nativeElement.scrollHeight;
    }
  }

  processCommand(command: string): void {
    this.content.createEmbeddedView(this.promptTemplate, {text: command});
    if (command.length > 0) {
      this.terminalProvider.storeInMemory(command);
    }

    switch (command) {
      case 'about':
        this.content.createComponent(
          this.resolver.resolveComponentFactory(AboutComponent)
        );
        break;
      case 'contact':
        this.content.createComponent(
          this.resolver.resolveComponentFactory(ContactComponent)
        );
        break;
      case 'team':
        this.content.createComponent(
          this.resolver.resolveComponentFactory(TeamComponent)
        );
        break;
      case 'help':
        this.content.createComponent(
          this.resolver.resolveComponentFactory(HelpComponent)
        );
        this.terminalProvider.storeInMemory(command);
        break;
        case 'motto':
        this.content.createComponent(
          this.resolver.resolveComponentFactory(MottoComponent)
        );
        this.terminalProvider.storeInMemory(command);
        break;
      case 'clear':
        this.content.clear();
        this.terminalProvider.storeInMemory(command);
        break;
      case '':
        break;
      default:
        const instance = this.content.createComponent(
          this.resolver.resolveComponentFactory(CommandNotFoundComponent),
        ).instance;
        instance['command'] = command;
    }
    this.showHelp = false;
  }

  toogleHelp(): void {
    this.showHelp = !this.showHelp;
  }
}
