import { TerminalProvider } from './providers/terminal.provider';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TerminalComponent } from './terminal/terminal.component';

import localeSv from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
import { HelpComponent } from './commands/help/help.component';
import { ContactComponent } from './commands/contact/contact.component';
import { AboutComponent } from './commands/about/about.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { CommandNotFoundComponent } from './commands/command-not-found/command-not-found.component';
import { MottoComponent } from './commands/motto/motto.component';
import { TeamComponent } from './commands/team/team.component';

registerLocaleData(localeSv, 'sv');

@NgModule({
  declarations: [
    AppComponent,
    TerminalComponent,
    HelpComponent,
    ContactComponent,
    AboutComponent,
    WelcomeMessageComponent,
    CommandNotFoundComponent,
    MottoComponent,
    TeamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    TerminalProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
