import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class TerminalProvider {
    private memory = [];
    private memoryIndex = 0;
    private input = '';
    public userInput: BehaviorSubject<string> = new BehaviorSubject<string>(this.input);
    public commandExecuted: Subject<string> = new Subject<string>();

    constructor() {
    }

    public onKeyPress(event: KeyboardEvent): void {
      switch (event.keyCode) {
        case 8:
          this.input = this.input.substring(0, this.input.length - 1);
          break;
        case 13:
          this.execute(this.input);
          this.input = '';
          break;
        case 38:
          this.input = this.getNextFromMemory();
          break;
        case 40:
          this.input = this.getPrevFromMemory();
          break;
        default:
          if (event.key.length === 1) {
            this.input += event.key;
          }
      }
      this.userInput.next(this.input);
    }

    public execute(command): void {
      this.commandExecuted.next(command);
    }

    public getNextFromMemory(): string {
      if (this.memory.length === 0) {
          return '';
      }
      this.memoryIndex++;
      if (this.memoryIndex === this.memory.length) {
          this.memoryIndex = 0;
      }
      return this.memory[this.memoryIndex];
    }

    public getPrevFromMemory(): string {
      if (this.memory.length === 0) {
          return '';
      }
      this.memoryIndex--;
      if (this.memoryIndex === -1) {
          this.memoryIndex = this.memory.length - 1;
      }
      return this.memory[this.memoryIndex];
    }

    public storeInMemory(command): void {
      const exist = this.memory.find(c => c === command);
      if (!exist) {
        this.memory.unshift(command);
      }
    }
}
