import { Component, OnInit } from '@angular/core';
import * as dateFormat from 'dateformat';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.css']
})
export class WelcomeMessageComponent implements OnInit {
  welcomeMessage = 'Welcome to it-e. Type \'help\' to view your options.';
  lastLogin = 'Last login: ' + dateFormat(Date.now(), 'dddd, mmmm dS, yyyy, HH:MM:ss');

  constructor() { }

  ngOnInit(): void {
  }

}
